import { Component, OnInit } from '@angular/core';
import * as data from "./JSON/technologies.json"
import * as projectsData from "./JSON/projects.json"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  techList = data.tech;

  projectsList = projectsData.projects;
  images = [
    "assets/images/projectsScreenshots/PlannedIt-Screenshots/PlannedIt-screenshot.png",
    "assets/images/projectsScreenshots/PlannedIt-Screenshots/Plannedit_Screenshot2.png",
    "assets/images/projectsScreenshots/PlannedIt-Screenshots/Plannedit_Screenshot3.png",
    "assets/images/projectsScreenshots/PlannedIt-Screenshots/Plannedit_screenshot4.png"
  ];

  constructor() { }

  ngOnInit() {
  }

}